import React, { useRef, useEffect, Fragment, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import instructions from './instructions.png';
import publicKeySignup from 'lib/publicKeySignup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import ContactFAQ from 'components/ContactFAQ';
import CaptureTrialCode from './CaptureTrialCode';
import TrialLoading from './TrialLoading';
import TrialReady from './TrialReady';

const urlParams = new URLSearchParams(window.location.search);
const urlParam = urlParams.get("trial_code");

export default function TrialNoPayment(props) {
	const {
		onFinished,
		messageUser,
	} = props;

	const [loading, setLoading] = useState(false);
	const [trialIsReady, setTrialIsReady] = useState(false);
	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [trialCode, setTrialCode] = useState(urlParam ? atob(urlParam) : '');

	const validate = (trialCode) => {
		if (trialCode.length > 0) return true;
		return false;
	}

	const submitTrialCode = () => {
		if (validate(trialCode)) {
			setError(false);
			setLoading(true);
		} else {
			setError(true);
			setErrorText('seems your trial code format is invalid');
		}
	}

	useEffect(() => {
		let isMounted = true;

		if (!loading) return;

		const trialCodeValidated = JSON.parse(sessionStorage.getItem('trial_code_validated'));
		if (!trialCodeValidated) {
			sessionStorage.setItem('payment_provider', 'trial');
		  sessionStorage.setItem('subscriptionID', trialCode);

		  const onSuccess = (response) => {
		  	if (isMounted) {
			    setLoading(false);
			    onFinished();
		  	}
		  	sessionStorage.setItem('trial_code_validated', true);
		    messageUser('trial subscription ready!', 'success');
		  }

		  const onError = error => {
		  	if (isMounted) {
		  		setLoading(false);
		  	}
		  	sessionStorage.setItem('trial_code_validated', false);
		    console.error(error.response.data.error);
		    messageUser(error.response.data.error, 'error');
		  }

			setLoading(true);
			const extras = {
				country: 'null',
				timestamp: new Date().toUTCString()
			}
			if(navigator.geolocation) {
				const success = (position) => {
					let { latitude: lat, longitude: lng } = position.coords
					extras.country = { lat, lng };
					publicKeySignup(onSuccess, onError, extras);
				}
				const error = (err) => {
					publicKeySignup(onSuccess, onError, extras);
				}
				messageUser('helps us locate our customers', 'info', {vertical:'top', horizontal:'center'})
		    navigator.geolocation.getCurrentPosition(success, error, { timeout: 15000 });
		  } else {
				publicKeySignup(onSuccess, onError, extras);
		  }
		} else {
			setLoading(false);
			setTrialIsReady(true);
		}

		return () => { isMounted = false }
	}, [loading])

	useEffect(() => {
		let isMounted = true;

		const trialCodeValidated = JSON.parse(sessionStorage.getItem('trial_code_validated'));
		if (trialCodeValidated) {
			if (isMounted) setTrialIsReady(true);
		}

		return () => { isMounted = false }
	}, [])

	return (
		<React.Fragment>
			{
				!trialIsReady && !loading && (
					<CaptureTrialCode setTrialCode={setTrialCode}
	  										  	error={error}
	  										  	errorText={errorText}
	  										  	submitTrialCode={submitTrialCode}
	  										  	defaultValue={trialCode}
				  />
				)
			}
  		{loading && <TrialLoading />}
      {trialIsReady && <TrialReady />}
    </React.Fragment>
	)
}