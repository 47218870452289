import React from 'react';
import BaseSubscriptionType from './BaseSubscriptionType';
import SvgIcon from '@material-ui/core/SvgIcon';
import {makeStyles} from '@material-ui/core/styles';

function SubscriptionMedia() {
  const classes = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '20%',
      height: '100%',
    },
  }))();

  return (
    <div className={classes.root}>
      <SvgIcon fontSize="large">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path d="M0 0h24v24H0z" fill="none"/><path d="M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4V6zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z"/></svg>
      </SvgIcon>
    </div>
  );
}

export default function SingleSubscription(props) {
  const {
    ...rest
  } = props;
	const heading = "Trial";
  const text = "1 week trial, supports 2 devices";
  const devices = 2;
  const price = '$0/0元';

  return (
    <BaseSubscriptionType
    	SubscriptionMedia={SubscriptionMedia}
    	heading={heading}
    	text={text}
    	devices={devices}
    	price={price}
    	actionsLabel={`select ${heading}`}
      {...rest}
    />
  )
}
