import React, { useEffect, Fragment, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import publicKeySignup from 'lib/publicKeySignup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ContactFAQ from 'components/ContactFAQ';

export default function CaptureTrialCode(props) {
	const {
		submitTrialCode,
		setTrialCode,
		error,
		errorText,
		defaultValue,
	} = props;

	return (
		<Grid container justifyContent="center">
			<Grid item>
	      <Stack>
    			<Typography align="center">1 Week Trial Subscription</Typography>
					<br />
		      <TextField onChange={event=>setTrialCode(event.target.value)}
		      					 sx={{ width: "25vw" }}
		      					 error={error}
		      					 helperText={errorText}
		      					 label="Trial code here" variant="standard"
		      					 defaultValue={defaultValue}
				 	/>
		      <br />
		      <Box sx={{ display: 'flex', flexDirection: 'column',
				    					justifyContent: 'center', alignItems: 'center',
			    						height: '20%',
    		 			 }}
		      >
			      <Button onClick={submitTrialCode}
			      				variant="contained" color="primary">
	          	submit
	        	</Button>
			      <br />
			      <Typography variant="body1" align="center">
							{"Don't have a trial code?"}
						</Typography>
						<ContactFAQ />
	    		</Box>
    		</Stack>
			</Grid>
		</Grid>
	)
}