import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import instructions from './instructions.png';

export default function TrialLoading(props) {
	return (
		<Fragment>
			<br />
			<br />
			<br />
			<Box sx={{ display: "flex", justifyContent: "center" }}>
	  		<CircularProgress color="primary" />
  		</Box>
			<Typography align="center">
				{'We are preparing your free trial subscription.'}
			</Typography>
			<Typography align="center">
				{'Take this moment to download the free mobile app.'}
			</Typography>
			<br />
			<br />
			<br />
			<img width="50%" src={instructions} />
    </Fragment>
	)
}
