import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import instructions from './instructions.png';

export default function TrialReady(props) {
	return (
		<Fragment>
			<br />
			<br />
			<br />
			<br />
			<Typography align="center">
				{'Your free trial subscription is ready!'}
			</Typography>
			<Typography align="center">
				{'Next step, download the free mobile app.'}
			</Typography>
			<br />
			<br />
			<br />
			<img width="50%" src={instructions} />
		</Fragment>
	)
}