import React, { useEffect } from 'react';
import BasicSignup from './BasicSignup';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import emailSignup from 'lib/emailSignup';

export default function Signup(props) {
	const {
		onFinished,
		setSnackbarMessage,
		setOpen,
		messageUser,
		disabled,
		...rest
	} = props;
	const [busy, setBusy] = React.useState(false);

	const onError = error => {
		setBusy(false);
		if (typeof error.response === 'object') {
			if ('status' in error.response) {
				if (error.response.status === 409) {
					messageUser(error.response.data.error, 'warn');
				} else if (error.response.status === 400) {
					messageUser('try another email', 'error');
				} else {
					messageUser(error.toString(), 'error');
				}
			} else {
				messageUser(error.toString(), 'error');
			}
		} else {
			messageUser(error.toString(), 'error');
		}
	}

	const onSuccess = response => {
		onFinished();
	}

	function setSignupEmail(email) {
		sessionStorage.setItem('email', email);
		emailSignup(onSuccess, onError);
	}

	useEffect(() => {
		if (disabled) onFinished();
	}, [])

  return (
  	<React.Fragment>
  		{busy ? <LinearProgress /> : null}
	    <BasicSignup
	    	setSignupEmail={setSignupEmail}
	    	setBusy={setBusy}
	    	busy={busy}
    	/>
  	</React.Fragment>
  );
}
