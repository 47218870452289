import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Infographic from './Infographic';

export default function Overview(props) {
	const {...rest} = props;
  const classes = makeStyles((theme) => ({
		root: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
	}))();

	return (
		<div className={classes.root}>
			<Infographic />
		</div>
	)
}
