import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import loadScript from 'lib/loadScript';
import SubscriptionPayment from './SubscriptionPayment';
import OnetimePayment from './OnetimePayment';
import renderSubscriptionPaymentButtons from './renderSubscriptionPaymentButtons';
import TrialNoPayment from './TrialNoPayment';

// window.onload(() => loadScript('capture'));
window.onload(() => loadScript('subscription', true));

export default function Payment(props) {
  const {
    onFinished,
    goBack,
    messageUser,
    ...rest
  } = props;
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const loadButtons = () => {
    window.paypal.getFundingSources().forEach(function(fundingSource) {
      const button = window.paypal.Buttons({fundingSource: fundingSource});
      const paypalButton = document.getElementById('paypal-button-container');
      if (paypalButton && button.isEligible()) button.render(paypalButton);
    });
    setLoadingButton(false);
  }

  React.useEffect(() => {
    let isMounted = true;

    if (sessionStorage.getItem('subscription') === "Trial") {
      if (!isMounted) return;
      console.log('setting up trial subscription, please standby');
      return;
    } else if (sessionStorage.getItem('subscriptionID')) {
      if (!isMounted) return;
      onFinished();
    } else if (!sessionStorage.getItem('subscription')) {
      if (!isMounted) return;
      goBack();      
    }

    const args = {
      setSaving,
      onFinished,
      setLoadingButton,
    }
    if (!('paypal' in window)) {
      setLoadingButton(true);
      setTimeout(() => {
        if (!isMounted) return;
        renderSubscriptionPaymentButtons(args);
      }, 3000);
    } else {
      if (!isMounted) return;
      renderSubscriptionPaymentButtons(args);
    }

    return () => { isMounted = false };
  }, []);

  return (
    <React.Fragment>
      {
        sessionStorage.getItem('subscription') === "Trial" ?
        <TrialNoPayment onFinished={onFinished} messageUser={messageUser} /> :
        <React.Fragment>
          <SubscriptionPayment loadingButton={loadingButton} saving={saving} goBack={goBack} />
          <Divider />
          <Typography align="center">or</Typography>
          <Divider />
          <OnetimePayment />   
        </React.Fragment>
      }
    </React.Fragment>
  )
}

