import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import EasyDownload from './EasyDownload';
import WorkAround from './WorkAround';

export default function Download(props) {
	const {
		goBack,
		onFinished,
		...rest
	} = props;
  const classes = makeStyles((theme) => ({
		root: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			// justifyContent: 'center',
			// alignItems: 'center',
		},
	}))();

	return (
		<div className={classes.root}>
			<EasyDownload goBack={goBack} onFinished={onFinished} />
			<Divider />
			<WorkAround goBack={goBack} onFinished={onFinished} />
		</div>
	)
}
