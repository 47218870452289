import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GetAppIcon from '@material-ui/icons/GetApp';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import VpnLockIcon from '@material-ui/icons/VpnLock';

export function QRCodeIcon(props) {
  return (
    <SvgIcon fontSize="small">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><g><rect fill="none" height="24" width="24"/></g><path d="M15,21h-2v-2h2V21z M13,14h-2v5h2V14z M21,12h-2v4h2V12z M19,10h-2v2h2V10z M7,12H5v2h2V12z M5,10H3v2h2V10z M12,5h2V3h-2V5 z M4.5,4.5v3h3v-3H4.5z M9,9H3V3h6V9z M4.5,16.5v3h3v-3H4.5z M9,21H3v-6h6V21z M16.5,4.5v3h3v-3H16.5z M21,9h-6V3h6V9z M19,19v-3 l-4,0v2h2v3h4v-2H19z M17,12l-4,0v2h4V12z M13,10H7v2h2v2h2v-2h2V10z M14,9V7h-2V5h-2v4L14,9z M6.75,5.25h-1.5v1.5h1.5V5.25z M6.75,17.25h-1.5v1.5h1.5V17.25z M18.75,5.25h-1.5v1.5h1.5V5.25z"/></svg>
    </SvgIcon>
  );
}

export function LoginIcon(props) {
  return (
    <SvgIcon fontSize="small">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"/></g></svg>
    </SvgIcon>
  );
}

export function DeviceTypeIcon(props) {
  return <DevicesOtherIcon />
}

export function PaymentIcon(props) {
  return <CreditCardIcon />
}

export function DownloadAppIcon(props) {
  return <GetAppIcon />
}

export function ConfigAppIcon(props) {
  return <PhonelinkSetupIcon />
}

export function SecureConnectionIcon(props) {
  return <VpnLockIcon />
}