import React from 'react';
import SingleSubscription from './SingleSubscription';
import TrialSubscription from './TrialSubscription';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {encodeBase64} from 'tweetnacl-util';
import {box} from 'tweetnacl';

export default function Subscriptions(props) {
	const {
		onFinished,
		goBack,
		...rest
	} = props;
	const classes = makeStyles((theme) => ({
		fullHeight: {
    	height: '100%',
		},
    subscriptions: {
    	display: 'flex',
    	flexDirection: 'column',
    	justifyContent: 'space-evenly',
    },
  }))();
	const [subscription, setSubscription] = React.useState(sessionStorage.getItem('subscription'));

	function genKeys() {
		const asian_keys = [box.keyPair(), box.keyPair()];
		const europe_keys = [box.keyPair(), box.keyPair()];
		const montreal_keys = [box.keyPair(), box.keyPair()];
		const us_keys = [box.keyPair(), box.keyPair()];
		if (!sessionStorage.getItem('publicKeys') || !sessionStorage.getItem('privateKeys')) {
			const publicKeys = [
				{
					asia: encodeBase64(asian_keys[0].publicKey),
					europe: encodeBase64(europe_keys[0].publicKey),
					montreal: encodeBase64(montreal_keys[0].publicKey),
					us: encodeBase64(us_keys[0].publicKey),
				},
				{
					asia: encodeBase64(asian_keys[1].publicKey),
					europe: encodeBase64(europe_keys[1].publicKey),
					montreal: encodeBase64(montreal_keys[1].publicKey),
					us: encodeBase64(us_keys[1].publicKey),
				},
			]
			sessionStorage.setItem('publicKeys', JSON.stringify(publicKeys));
			const privateKeys = [
				{
					asia: encodeBase64(asian_keys[0].secretKey),
					europe: encodeBase64(europe_keys[0].secretKey),
					montreal: encodeBase64(montreal_keys[0].secretKey),
					us: encodeBase64(us_keys[0].secretKey),
				},
				{
					asia: encodeBase64(asian_keys[1].secretKey),
					europe: encodeBase64(europe_keys[1].secretKey),
					montreal: encodeBase64(montreal_keys[1].secretKey),
					us: encodeBase64(us_keys[1].secretKey),
				},
			]
			sessionStorage.setItem('privateKeys', JSON.stringify(privateKeys));
		}
	}

  const handleChange = (event) => {
    setSubscription(event.target.value);
		sessionStorage.setItem('subscription', event.target.value);
		genKeys();
		onFinished();
  };

  React.useEffect(() => {
  	if (sessionStorage.getItem('subscriptionID')) onFinished();
  }, []);

  return (
    <React.Fragment>
	    <FormControl className={classes.fullHeight} component="fieldset">
			  <FormLabel component="legend">Monthly Recurring Subscription Plans</FormLabel>
			  <RadioGroup
			  	className={clsx([classes.fullHeight, classes.subscriptions])}
			  	aria-label="subscription plans"
			  	name="subscription_plan"
			  	value={subscription}
			  	onChange={handleChange}
		  	>
	  	    <TrialSubscription raised={subscription ? subscription.toLowerCase().includes('trial') : true} />
	  	    <SingleSubscription raised={subscription ? subscription.toLowerCase().includes('single') : true} />
	      </RadioGroup>
			</FormControl>
    </React.Fragment>
  )
}
