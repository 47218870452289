import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import getConfig from 'lib/getConfig';

export default function DesktopDownload(props) {
	const {
		onFinished,
		goBack,
		messageUser,
		...rest
	} = props;
	const classes = makeStyles((theme) => ({
		link: {
			'& > * + *': {
	      marginLeft: theme.spacing(2),
	    },
		},
	}))();
	const [loading, setLoading] = React.useState(false);

	function onClick(event) {
		event.preventDefault();

		const hasSubscription = sessionStorage.getItem('subscription');
		const hasSubscriptionID = sessionStorage.getItem('subscriptionID');
		const isTrial = sessionStorage.getItem('subscription') === "Trial";
    if (!isTrial && (!hasSubscription || !hasSubscriptionID)) {
    	return messageUser('please make a payment', 'warn');
    }

		if (isTrial) {
			const paymentProvider = sessionStorage.getItem('payment_provider');
			if (paymentProvider !== "trial") {
				return messageUser('please complete trial setup', 'warn');
			}
		}

		setLoading(true);
		function onSuccess(response) {
			const privateKeys = JSON.parse(sessionStorage.getItem('privateKeys'));
			const publicKeys = JSON.parse(sessionStorage.getItem('publicKeys'));
			const files = response.data.map(data => {
				const filename = data.filename;
				const privateKey = publicKeys[0][filename] === data.publicKey
												 ? privateKeys[0][filename]
												 : privateKeys[1][filename];
				const config = data.config.replace('REPLACE_ME', privateKey);
				return {filename, config};
			});
			files.forEach(file => {
				const filename = file.filename + '.conf';
			  const url = window.URL.createObjectURL(new Blob([file.config]));
			  const link = document.createElement('a');
			  link.href = url;
			  link.setAttribute('download', filename);
			  document.body.appendChild(link);
			  link.click();
			});
		  setLoading(false);
		  onFinished();
		}
		function onError(error) {
			console.error(error);
			messageUser(error.response.data.error, 'error');
		}
		getConfig(onSuccess, onError);
	}

	return (
		<div>
	    <Link
		    className={classes.link}
	    	href=""
	    	download={process.env.REACT_APP_RADAR_CONFIG_URL}
	    	onClick={onClick}
	    	variant="body2"
	    	target="_blank"
	    	rel="noreferrer"
	  	>
	  		<Typography>
					Download config files
		    </Typography>
	    </Link>
	    {loading ? <LinearProgress color="primary" /> : null}
		</div>
	)
}