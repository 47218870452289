import 'lib/loader';
import React from 'react';
import VerticalLinearStepper from 'components/VerticalLinearStepper';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
	steps,
	stepsContent,
} from './steps';
import Overview from 'pages/Overview';
import Signup from 'pages/Signup';
import Subscriptions from 'pages/Subscriptions';
import Payment from 'pages/Payment';
import Download from 'pages/Download';
import Setup from 'pages/Setup';
import Connect from 'pages/Connect';
import CustomSnackbar, {
	SNACKBAR_RED,
	SNACKBAR_ORANGE,
	SNACKBAR_BLUE,
	SNACKBAR_GREEN,
} from 'components/CustomSnackbar';

function App() {
  const classes = makeStyles((theme) => ({
		root: {
			width: '100vw',
			height: '100vh',
			display: 'flex',
			flexDirection: 'row',
		},
		stepper: {
			width: '30%',
		},
		divider: {
			height: '100%',
		},
		main: {
		  width: '70%',
			height: '100%',
		  display: 'flex',
		  flexDirection: 'column',
		},
	}))();
	const [activeStep, setActiveStep] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	const [severity, setSeverity] = React.useState(SNACKBAR_RED);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [anchorOrigin, setAnchorOrigin] = React.useState({vertical: 'bottom', horizontal: 'center'});
  const autoHideDuration = 4500;

	sessionStorage.setItem('wg', process.env.REACT_APP_WG);
	sessionStorage.setItem('px', process.env.REACT_APP_PX);

	function onFinished() {
		setActiveStep(activeStep + 1);
	}

	function goBack() {
		setActiveStep(activeStep - 1);
	}

	function messageUser(message, level='info', anchorOrigin) {
		if (level === 'error') {
			setSeverity(SNACKBAR_RED);
		} else if (level === 'warn') {
			setSeverity(SNACKBAR_ORANGE);
		} else if (level === 'success') {
			setSeverity(SNACKBAR_GREEN);
		} else if (level === 'info') {
			setSeverity(SNACKBAR_BLUE);
		}
		if (anchorOrigin) setAnchorOrigin(anchorOrigin);
		setSnackbarMessage(message);
		setOpen(true);
	}

	React.useEffect(() => {
		if (sessionStorage.getItem('subscription')) {
			setActiveStep(3);
		} else if (sessionStorage.getItem('email')) {
			setActiveStep(2);
		}
	}, []);

	React.useEffect(() => {
		if (open) setTimeout(setOpen, autoHideDuration, false);
	}, [open]);

	return (
		<div className={classes.root}>
			<nav className={classes.stepper}>
				<VerticalLinearStepper
					steps={steps}
					stepsContent={stepsContent}
					activeStep={activeStep}
					disabledSteps={[1]}
					setActiveStep={setActiveStep}
				/>
			</nav>
	    <Divider
		    className={classes.divider}
	    	orientation="vertical"
	    	variant="middle"
    	/>
    	<main className={classes.main}>
    		{activeStep === 0 ? <Overview /> : null}
    		{activeStep === 1 ? <Signup disabled onFinished={onFinished} goBack={goBack} messageUser={messageUser} /> : null}
    		{activeStep === 2 ? <Subscriptions onFinished={onFinished} goBack={goBack} messageUser={messageUser} /> : null}
    		{activeStep === 3 ? <Payment onFinished={onFinished} goBack={goBack} messageUser={messageUser} /> : null}
    		{activeStep === 4 ? <Download onFinished={onFinished} goBack={goBack} messageUser={messageUser} /> : null}
    		{activeStep === 5 ? <Setup onFinished={onFinished} goBack={goBack} messageUser={messageUser} /> : null}
    		{activeStep === 6 ? <Connect onFinished={onFinished} goBack={goBack} messageUser={messageUser} /> : null}
	    </main>
	    <CustomSnackbar
				open={open}
				severity={severity}
				snackbarMessage={snackbarMessage}
				autoHideDuration={autoHideDuration}
				anchorOrigin={anchorOrigin}
			/>
		</div>
	);
}

export default App;
