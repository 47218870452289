import React from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import Loading from './Loading';

export default function SubscriptionPayment(props) {
  const {
    goBack,
    loadingButton,
    saving,
    ...rest
  } = props;

  const classes = makeStyles((theme) => ({
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20%',
    },
    paypal: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '75%',
    },
  }))();

  return (
    <React.Fragment>
      <Typography align="center">Monthly Recurring Payment</Typography>
      <div className={classes.buttons}>
        {loadingButton ? <Loading /> : null}
        {saving ? <LinearProgress /> : null}
        <div className={classes.paypal} id="paypal-subscription_payment-button-container"></div>
      </div>
    </React.Fragment>
  );
}