import React from 'react';
import Typography from '@material-ui/core/Typography';
import wechat_payment_qrcode from "./wechat_payment_qrcode_anonymized.png";
import alipay_payment_qrcode from "./alipay_payment_qrcode_anonymized.png";
import {makeStyles} from '@material-ui/core/styles';

export default function OnetimePayment(props) {
  const {
    goBack,
    loadingButton,
    saving,
    ...rest
  } = props;

  const classes = makeStyles((theme) => ({
    qrcodes: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    qrcode: {
      width: '20%',
      border: '1px solid black',
    },
  }))();

  return (
    <React.Fragment>
      <Typography align="center">One Month Non-Recurring Payment</Typography>
      <div className={classes.qrcodes}>
        <img className={classes.qrcode} src={wechat_payment_qrcode} />
        <img className={classes.qrcode} src={alipay_payment_qrcode} />
      </div>
      <Typography align="center">a member of our team will</Typography>
      <Typography align="center">contact you within 24 hours</Typography>
    </React.Fragment>
  );
}