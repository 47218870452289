import publicKeySignup from 'lib/publicKeySignup';

export default function renderSubscriptionPaymentButtons(args) {
  const {
    setSaving,
    onFinished,
    setLoadingButton,
  } = args;

  if ('paypal' in window) {
    const button = window.paypal.Buttons({
      fundingSource: window.paypal.FUNDING.PAYPAL,
      createSubscription: function(data, actions) {
        const subscription = sessionStorage.getItem('subscription');
        if (subscription.toLowerCase() === 'organization') {
          return actions.subscription.create({
            plan_id: process.env.REACT_APP_RADAR_PAYPAL_ORGANIZATION_PLANID,
            quantity: sessionStorage.getItem('quantity'),
          });
        } else if (subscription.toLowerCase() === 'single') {
          return actions.subscription.create({
            plan_id: process.env.REACT_APP_RADAR_PAYPAL_SINGLE_PLANID,
          });
        } else {
           return actions.subscription.create({
            plan_id: process.env.REACT_APP_RADAR_PAYPAL_FRIENDS_AND_FAMILY_PLANID,
          });
        }
      },
      onApprove: function(data, actions) {
        sessionStorage.setItem('payment_provider', 'paypal');
        sessionStorage.setItem('subscriptionID', data.subscriptionID);
        console.log('You have successfully created subscription ' + data.subscriptionID);
        const onSuccess = () => {
          setSaving(false);
          onFinished();
        }
        const onError = error => {
          setSaving(false);
          console.error(error.response);
          onFinished();
        }
        setSaving(true);
        publicKeySignup(onSuccess, onError);
      },
    });
    const paypalButton = document.getElementById('paypal-subscription_payment-button-container');
    if (paypalButton && button.isEligible()) {
      button.render(paypalButton);
      setLoadingButton(false);
    }
  } else {
    console.error('paypal subscription script not loading');
  }
}