import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import DevicesIcon from '@material-ui/icons/Devices';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';


function Discount(props) {
  const {
    discount,
    ...rest
  } = props;
  const classes = makeStyles((theme) => ({
    root: {
      ...theme.typography.body1,
      display: 'flex',
      color: theme.productColors.orangeRed,
    },
  }))();
  return (
    <div className={classes.root}>
      <LocalOfferIcon fontSize="small" />
      <Typography className={classes.root}>{discount}% discount</Typography>
    </div>
  )
}

function Quantity() {
  const classes = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '75%',
    },
  }))();
  const [quantity, setQuantity] = React.useState(10);
  const [error, setError] = React.useState(false);

  function onChange(event) {
    const isValidNumber = `^[0-9]{1,32}$`;
    if (event.target.value.match(isValidNumber)) {
      setQuantity(event.target.value);

      if (Number(event.target.value) >= 10) {
        sessionStorage.setItem('quantity', event.target.value);
        setError(false);
      } else {
        setError(true);
      }
    }
  }

  return (
    <div className={classes.root}>
      <TextField
        label={error ? "minimum 10 devices" : ''}
        error={error}
        variant="outlined"
        size="small"
        type="text"
        fullWidth={true}
        value={quantity}
        onChange={onChange}
      />
    </div>
  )
}

export default function BaseSubscriptionType(props) {
  const {
    SubscriptionMedia,
    heading,
    text,
    devices,
    price,
    actions,
    raised,
    actionsLabel,
    discount,
    quantity,
    ...rest
  } = props;
  const classes = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    heading: {
      ...theme.typography.h3,
      [theme.breakpoints.between('xs', 'sm')]: {
        ...theme.typography.h4,
      },
    },
    text: {
      ...theme.typography.subtitle1,
      color: theme.palette.secondary.main,
    },
    price: {
      ...theme.typography.subtitle2,
      color: theme.palette.secondary.dark,
    },
    discount: {
      ...theme.typography.body1,
      display: 'flex',
      color: theme.productColors.orangeRed,
    },
    alacarteFab: {
      backgroundColor: theme.palette.secondary.light,
    },
    alacarteIcon: {
      color: theme.palette.secondary.contrastText,
    },
  }))();
  const [alacarte, setAlacarte] = React.useState(false);

  return (
    <Card
      className={classes.root}
      raised={raised}
    >
      <CardMedia
        component={SubscriptionMedia}
      />
      <Divider flexItem={true} orientation='vertical' variant='middle' />
      <div className={classes.details}>
        <CardContent>
          <Typography component="h1" className={classes.heading} noWrap={true}>
            {heading}
          </Typography>
          <Typography className={classes.text} noWrap={true}>
            {text}
          </Typography>
          <Typography className={classes.price} noWrap={true}>
            {devices} devices for {price}
          </Typography>
          {
            discount
            ? <Discount discount={discount} />
            : null
          }
          {
            quantity
            ? <Quantity />
            : null
          }
        </CardContent>
        <CardActions>
          <FormControlLabel value={heading} control={<Radio />} label={actionsLabel} />
          {
            alacarte
            ? <Fab size="small" aria-label="add" className={classes.alacarteFab}>
                <AddIcon fontSize="small" className={classes.alacarteIcon} />
              </Fab>
            : null
          }
        </CardActions>
      </div>
    </Card>
  );
}
