import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

function Email(props) {
	return (
		<React.Fragment>
			<Typography variant="body1" align="center">Protect your anonymity with free anonymous email from
				<a href="https://protonmail.com/signup" target="_blank" rel="noreferrer noopener">
					&nbsp;protonmail
				</a>
			</Typography>
		</React.Fragment>
	)
}

export default function BasicSignup(props) {
	const {
		setSignupEmail,
		setBusy,
		busy,
	} = props;
	const classes = makeStyles((theme) => ({
		error: {
			color: 'red',
		},
		optional: {
			color: theme.productColors.orangeYellow,
		},
	}))();
	const [email, setEmail] = React.useState(sessionStorage.getItem('email') || '');
	const [error, setError] = React.useState(false);

	function onChange(event) {
		setEmail(event.target.value);
	}

	function onClick() {
		if (validateEmail(email)) {
			setError(false);
			setBusy(true);
			setSignupEmail(email);
		} else {
			setError(true);
		}
	}

	function validateEmail() {
    if (!email || !email.length) return false;
    const isEmail = /^.+@.+\..{2,24}$/
    return email.match(isEmail) !== null;
  }

	return (
		<React.Fragment>
			<Typography variant="body1" align="center">
				We <strong><u>only</u></strong> use your email for communication regarding this service
			</Typography>
			<FormControl>
			  <InputLabel htmlFor="my-input">Email address <span className={classes.optional}>(optional)</span></InputLabel>
			  <Input aria-describedby="verify your email" onChange={onChange} value={email} error={error} />
			  {
			  	error
			  	? <FormHelperText className={classes.error}>emails don't look like that</FormHelperText>
			  	: null
			  }
			  <Button
			  	variant="contained"
		  		color="primary"
		  		disableElevation
		  		onClick={onClick}
		  		disabled={busy}
	  		>
				  save
				</Button>
				<Email />
			</FormControl>
		</React.Fragment>
	)
}