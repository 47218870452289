import { createTheme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  typography,
  branding: {
    color: '#535de6',
  },
  productColors: {
  	green: '#82DD27',
    greenBlue: '#146e6c',
  	orangeRed: '#de5628',
  	redOrange: '#de2828',
  	orangeYellow: '#deb128',
  	yellow: '#dede28',
		orange: '#de8328',
		blue: '#282ade',
		purple: '#4b4dc5',
		teal: '#28dedb',
		pink: '#de28de',
	  white: '#FFF',
	  black: '#000',
  },
});

export default theme;
