import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function EasyDownload(props) {
	const {
		onFinished,
		goBack,
		...rest
	} = props;
  const classes = makeStyles((theme) => ({
		root: {
			// width: '100%',
			// height: '100%',
			display: 'flex',
			flexDirection: 'column',
			// justifyContent: 'center',
			alignItems: 'center',
		},
		link: {
			'& > * + *': {
	      marginLeft: theme.spacing(2),
	    },
		},
		divider: {
			width: '100%',
			height: 5,
		},
	}))();

	function onClickDesktop(event) {
		event.preventDefault();
		const wg = atob(process.env.REACT_APP_WG);
		const url =`https://www.${wg}.com/install/`;
		window.open(url, "", "noreferrer");
		onFinished();
	}

	function onClickIOS(event) {
		event.preventDefault();
		const wg = atob(process.env.REACT_APP_WG);
		const url =`https://apps.apple.com/us/app/${wg}/id1441195209`;
		window.open(url, "", "noreferrer");
		onFinished();
	}

	function onClickAndroid(event) {
		event.preventDefault();
		const wg = atob(process.env.REACT_APP_WG);
		const url =`https://play.google.com/store/apps/details?id=com.${wg}.android&hl=en_US&gl=US`;
		window.open(url, "", "noreferrer");
		onFinished();
	}

  return (
  	<div className={classes.root}>
	    <Typography className={classes.link}>
	    	Download & install the app
	    </Typography>
	    <Typography className={classes.link}>
	      <Link
	      	onClick={onClickDesktop}
	      	variant="body2"
	      	target="_blank"
	      	rel="noreferrer"
      	>
	        Desktop
	      </Link>
	    </Typography>
	    <Typography className={classes.link} align="center">
	      <Link
	      	onClick={onClickIOS}
	      	variant="body2"
	      	target="_blank"
	      	rel="noreferrer"
      	>
	        iOS
	      </Link>
	    </Typography>
	    <Typography className={classes.link} align="center">
	    	<Link
	      	onClick={onClickAndroid}
	      	variant="body2"
	      	target="_blank"
	      	rel="noreferrer"
      	>
	        Android
	      </Link>	
      </Typography>	
    </div>
  );
}
