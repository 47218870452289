import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';
import DesktopDownload from './DesktopDownload';
import MobileDownload from './MobileDownload';

export default function Setup(props) {
	const {
		onFinished,
		goBack,
		messageUser,
		...rest
	} = props;
  const classes = makeStyles((theme) => ({
		root: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		divider: {
			width: '45%',
			height: '0.25%',
		},
	}))();

  return (
  	<div className={classes.root}>
			<DesktopDownload onFinished={onFinished} goBack={goBack} messageUser={messageUser} />
			<Divider
		    className={classes.divider}
	    	orientation="horizontal"
	    	variant="middle"
    	/>
    	<Typography>
	    	or
	    </Typography>
	    <Divider
		    className={classes.divider}
	    	orientation="horizontal"
	    	variant="middle"
    	/>
			<MobileDownload onFinished={onFinished} goBack={goBack} messageUser={messageUser} />
			<hr />
			<div>
	    	<Typography variant="body2" align="center">
		    	please be patient
		    </Typography>
		    <Typography variant="body2" align="center">
		    	this takes about 60 seconds
		    </Typography>
    </div>
    </div>
  );
}
