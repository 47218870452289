export const steps = [
	'Overview',
	'Signup (optional)',
	'Subscription',
	'Payment',
	'Download',
	'Setup',
	'Connect',
];

export const stepsContent = [
	"how to connect to the network",
	"used for correspondence",
	"number of devices",
	"pay for network access",
	"get the software",
	"configure the software",
	"connect to the software",
];
