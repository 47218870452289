import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

export default function ContactFAQ(props) {
	const {
		...rest
	} = props;
	const classes = makeStyles((theme) => ({
		root: {
		},
	}))();
	const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
	const userEmail = sessionStorage.getItem('email');
	const mailto = `mailto:${contactEmail}?subject=Problem or Question&from=${userEmail}`;

	return (
		<div className={classes.root}>
			<Typography variant="body1" align="center">Contact US at:</Typography>
			<Typography variant="body1" align="center">
				<a href={mailto} target="_blank" rel="noreferrer noopener">
					{contactEmail}
				</a>
			</Typography>
    </div>
	)
}