import axios from 'axios';

export default function emailSignup(onSuccess, onError) {
  const config = {
    url: process.env.REACT_APP_EMAIL_SIGNUP,
    responseType: 'json',
    method: 'post',
    headers: {
      'Accept': "application/json",
      'Content-Type' : 'application/json',
    },
    data: {
      publicKeys: btoa(sessionStorage.getItem('publicKeys')),
      payment_provider: sessionStorage.getItem('payment_provider'),
      subscriptionID: sessionStorage.getItem('subscriptionID'),
      subscription: sessionStorage.getItem('subscription'),
      email: sessionStorage.getItem('email') || '',
    },
  };
  axios(config)
  .then(onSuccess)
  .catch(onError);
}