export default function loadScript(intent, vault=false, callback) {
  const paypalScript = document.createElement('script');
  paypalScript.id = `paypal-${intent}-jssdk`;;
  paypalScript.setAttribute('async', true);
  paypalScript.setAttribute('defer', true);
  paypalScript.setAttribute('crossorigin','anonymous');
  const clientId = process.env.REACT_APP_RADAR_PAYPAL_CLIENTID;
  paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&vault=${vault}&intent=${intent}`;
  if (callback) paypalScript.onload = callback;
  const app = document.getElementById('root');
  app.parentNode.insertBefore(paypalScript, app);
}