import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import QRCode from 'qrcode-react';
import Grid from '@material-ui/core/Grid';
import getConfig from 'lib/getConfig';

export default function MobileDownload(props) {
	const {
		onFinished,
		goBack,
		messageUser,
		...rest
	} = props;
	const classes = makeStyles((theme) => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		link: {
			'& > * + *': {
	      marginLeft: theme.spacing(2),
	    },
		},
	}))();
	const [loading, setLoading] = React.useState(false);
	const [QRCodes, setQRCodes] = React.useState(undefined);

	function onClick(event) {
		event.preventDefault();

		const hasSubscription = sessionStorage.getItem('subscription');
		const hasSubscriptionID = sessionStorage.getItem('subscriptionID');
		const isTrial = sessionStorage.getItem('subscription') === "Trial";
    if (!isTrial && (!hasSubscription || !hasSubscriptionID)) {
    	return messageUser('please make a payment', 'warn');
    }

		if (isTrial) {
			const paymentProvider = sessionStorage.getItem('payment_provider');
			if (paymentProvider !== "trial") {
				return messageUser('please complete trial setup', 'warn');
			}
		}

		setLoading(true);
		function onSuccess(response) {
			const privateKeys = JSON.parse(sessionStorage.getItem('privateKeys'));
			const publicKeys = JSON.parse(sessionStorage.getItem('publicKeys'));
			const configs = response.data.map(data => {
				const filename = data.filename;
				const privateKey = publicKeys[0][filename] === data.publicKey
												 ? privateKeys[0][filename]
												 : privateKeys[1][filename];
				const config = data.config.replace('REPLACE_ME', privateKey);
				return {filename, config};
			});
			setQRCodes(
				<Grid
				  container
				  direction="row"
				  justify="space-between"
				  alignItems="center"
				  spacing={4}
				>
					{	
						configs.map(config => {
							return (
								<Grid item xs={12} sm={6} md={4}>
									<Typography>{config.filename}</Typography>
									<QRCode value={config.config} />
								</Grid>
							)
						})
					}
				</Grid>
			)
			setLoading(false);
		}
		function onError(error) {
			console.error(error);
		}
		getConfig(onSuccess, onError);
	}

	return (
		<div className={classes.root}>
			<Link
		    className={classes.link}
	    	href=""
	    	download={process.env.REACT_APP_RADAR_CONFIG_URL}
	    	onClick={onClick}
	    	variant="body2"
	    	target="_blank"
	    	rel="noreferrer"
	  	>
	  		<Typography>
					Scan QR codes
		    </Typography>
	    </Link>
	    {loading ? <Skeleton height="200px" width="150px" /> : null}
	    {QRCodes}
		</div>
	)
}