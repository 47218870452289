import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import {
  QRCodeIcon,
  DeviceTypeIcon,
  PaymentIcon,
  DownloadAppIcon,
  ConfigAppIcon,
  SecureConnectionIcon,
} from './Icons';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default function Infographic() {
  const classes = makeStyles((theme) => ({
    paper: {
      padding: '6px 16px',
    },
    start: {
      backgroundColor: theme.productColors.green,
    },
    end: {
      backgroundColor: theme.productColors.redOrange,
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    optional: {
      color: theme.productColors.orangeYellow,
    },
  }))();

  return (
    <Timeline align="alternate">
      {
        // <TimelineItem>
        //   <TimelineOppositeContent>
        //     <Typography variant="body2" color="textSecondary">
        //       recieve invite qr code
        //     </Typography>
        //   </TimelineOppositeContent>
        //   <TimelineSeparator>
        //     <TimelineDot className={classes.start}>
        //       <QRCodeIcon />
        //     </TimelineDot>
        //     <TimelineConnector />
        //   </TimelineSeparator>
        //   <TimelineContent>
        //     <Paper elevation={3} className={classes.paper}>
        //       <Typography variant="h3" component="h1">
        //         Invitation
        //       </Typography>
        //       <Typography>A valid invite code is required to signup</Typography>
        //     </Paper>
        //   </TimelineContent>
        // </TimelineItem>
      }
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" className={classes.start}>
            <DeviceTypeIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h3" component="h1">
              Subscribe
            </Typography>
            <Typography>Cost based on the number of devices</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
            <PaymentIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h3" component="h1">
              Checkout
            </Typography>
            <Typography>Pay the subscription fee</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
            <DownloadAppIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h3" component="h1">
              Download
            </Typography>
            <Typography>Download the app and config file(s)</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
            <ConfigAppIcon />
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h3" component="h1">
              Setup
            </Typography>
            <Typography>Import config files into the app</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot className={classes.end}>
            <SecureConnectionIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h3" component="h1">
              Done
            </Typography>
            <Typography>Connect to the network</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
