export default function copyToClipboard(event) {
	if (window.navigator && 'clipboard' in navigator) {
		const text = event.target.value;
		navigator.clipboard.writeText(text);
		event.target.select();
	} else {
		event.target.focus({preventScroll: true});
		const field = event.target.getAttribute('field');
		this.querySelector(`[field=${field}][type=edit]`).select();
		document.execCommand('copy');
	}
}
