import palette from './palette';
import "@fontsource/caveat";
import "@fontsource/montserrat";

export default {
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '12vmin',
    letterSpacing: '0.18vmin',
    lineHeight: '10vmin',
    fontFamily: 'Caveat',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 450,
    fontSize: '8vmin',
    letterSpacing: '0.15vmin',
    lineHeight: '8vmin',
    fontFamily: 'Montserrat',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '5vmin',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
    fontFamily: 'Montserrat',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '4vmin',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
    fontFamily: 'Montserrat',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
    fontFamily: 'Caveat',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
    fontFamily: 'Caveat',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '25px'
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px'
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '3vmin',
    letterSpacing: '-0.08vmin',
    lineHeight: '5vmin',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '4.5vmin',
    fontWeight: 400,
    letterSpacing: '-0.08vmin',
    lineHeight: '4vmin',
    fontFamily: 'Caveat',
  },
  button: {
    color: palette.text.primary,
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase'
  },
  notifications: {
    color: '#222222',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: '10px',
    textTransform: 'capitalize'
  },
  misc: {
    color: "#7A7A7A",
    fontFamily: 'Montserrat',
    fontSize: '8px',
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: '10px',
    textTransform: 'uppercase'
  },
};
