import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ExpandableList from 'components/ExpandableList';
import copyToClipboard from 'lib/copyToClipboard';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import TextField from '@material-ui/core/TextField';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';

export default function WorkAround(props) {
	const {...rest} = props;
  const classes = makeStyles((theme) => ({
  	root: {
  	// 	width: '100%',
			// height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
  	},
		header: {
			// width: '100%',
			// height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		link: {
			'& > * + *': {
	      marginLeft: theme.spacing(2),
	    },
			color: theme.productColors.black,
		},
	}))();

	const wg = atob(sessionStorage.getItem('wg'));
	const px = atob(sessionStorage.getItem('px'));

	return (
		<React.Fragment>
			<div className={classes.header}>
				<Typography className={classes.link}>
		    	Download links above not working?
		    </Typography>
		    <Typography className={classes.link}>
		    	See below for workarounds
		    </Typography>
			</div>
			<div className={classes.root}>
				<ExpandableList
					header="Desktop"
					icon={<DesktopMacIcon />}
					content={
						<React.Fragment>
							<Typography>
								Goto a proxy site, such as <a target="{px}" href={`https://${px}.com/`}>{px}.com</a>,
					    </Typography>
					    <Typography>
					    	and paste in the following url:
					    </Typography>
					    <Typography>
					    	<br />
					    	<TextField label="click to copy & paste" onClick={copyToClipboard} autoFocus fullWidth value={`https://www.${wg}.com/install/`} />
					    </Typography>
						</React.Fragment>
					}
				/>
				<ExpandableList
					header="iOS"
					icon={<AppleIcon />}
					content={
						<React.Fragment>
							<Typography>
								The iOS app comes from the US app store
					    </Typography>
					    <Typography>
								Below are two options to acquire a US apple ID:
					    </Typography>
					    <Typography variant="body1" align="left">
					    	<ol>
						    	<li>Purchase a US apple ID from taobao</li>
						    	<li><a target="{appleid}" href="https://appleid.apple.com/account">signup for an apple id</a> with <a target="fakeaddressgenerator" href="https://fakeaddressgenerator.org/usa_address_generator">spoofed info</a> and a free anonymous email address from <a target="protonmail" href="https://mail.protonmail.com/create/new">protonmail</a></li>
					    	</ol>
					    </Typography>
						</React.Fragment>
					}
				/>
				<ExpandableList
					header="Android"
					icon={<AndroidIcon />}
					content={
						<React.Fragment>
							<Typography>
					    	Alternatives to Google App store:
					    </Typography>
					    <Typography variant="body1" align="left">
					    	<ol>
						    	<li>
						    		<div>download from <a target="apkmirror" href={`https://www.apkmirror.com/apk/${wg}-development-team/`}>apk mirror</a></div> and then watch the video
						    		<iframe width="560" height="315" src="https://www.youtube.com/embed/lWmO4ZUdROc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						    		</li>
						    	<li>Install from alternative android app store <a target="f-droid" href={`https://f-droid.org/en/packages/com.${wg}.android/`}>fdroid</a></li>
					    	</ol>
					    </Typography>
						</React.Fragment>
					}
				/>
			</div>
		</React.Fragment>
	)
}
