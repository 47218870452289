import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import file from './file.png';
import select from './select.png';
import activate from './activate.png';
import done from './done.png';

export default function Connect(props) {
	const {
		onFinished,
		goBack,
		...rest
	} = props;
  const classes = makeStyles((theme) => ({
		root: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		image: {
			width: '25%',
			height: '25%',
		},
	}))();

  return (
  	<div className={classes.root}>
  		<ol>
  			<li>
					<Typography>Import config file</Typography>
  				<img className={classes.image} src={file} />
  			</li>
  			<li>
					<Typography>Select a server</Typography>
  				<img className={classes.image} src={select} />
  			</li>
  			<li>
  				<Typography>Click activate</Typography>
					<img className={classes.image} src={activate} />
  			</li>
  			<li>
					<Typography>You're done. Welcome to the network!</Typography>
					<img className={classes.image} src={done} />
  			</li>
  		</ol>
    </div>
  );
}
