import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';

let SNACKBAR_RED = 'error';
let SNACKBAR_ORANGE = 'warning';
let SNACKBAR_BLUE = 'info';
let SNACKBAR_GREEN = 'success';

export {
	SNACKBAR_RED,
	SNACKBAR_ORANGE,
	SNACKBAR_BLUE,
	SNACKBAR_GREEN,
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSnackbar(props) {
	const {
		open,
		closeSnackbar,
		severity,
		snackbarMessage,
		autoHideDuration,
		anchorOrigin,
		...rest
	} = props;
	const classes = makeStyles((theme) => ({
		root: {
      position: 'absolute',
      top: 500,
    },
	}))();

	return (
		<div className={classes.root}>
			<Snackbar
	      open={open}
	      autoHideDuration={autoHideDuration}
	      onClose={closeSnackbar}
	      anchorOrigin={anchorOrigin}
	    >
	      <Alert onClose={closeSnackbar} severity={severity}>
	        {snackbarMessage}
	      </Alert>
	    </Snackbar>
    </div>
	)
}